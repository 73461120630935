// start init.js

function formatNumber(n){
    return n.toLocaleString();
}

function setServicesCount(){
    var count = $('.services-popup-sidebar .custom_voucher_selected_items .selected-service').length;
    $(".js-number_services").text(count);

    if(count > 0){
        $('.js-submit-popup').removeClass('btn--disabled');
    }
    else {
        $('.js-submit-popup').addClass('btn--disabled');
    }
}

function searchServicesAjax(){
    var exceptIds = [];
    /*$("form .custom_voucher_selected_items label").each(function(){
        exceptIds.push($(this).attr("data-id"));
    });*/
    $.get( "/custom-voucher/search-services", {searchValue: $(".popup_custom_autocomplete").val(), "exceptIds[]": exceptIds }, function( data ) {
        var html = "";
        $.each(data, function(){
            html += '<div class="col-md-12"><div class="custom_autocomplete_group" data-counts-by-pieces="' + this.counts_by_pieces + '"><a name="serviceGroup-' + this.id + '" class="anchor"></a><h3 class="h2">' + this.name + ' (' + this.counts_by_pieces_label + ')</h3>';
            html += '<div class="form__row">';
            var is_accommodation = this.is_accommodation;
            var groupId = this.id;
            $.each(this.services, function(index){
                html += '<label data-id="' + this.id + '" class="form__checkbox" for="sluzba-' + this.id + '-' + groupId+ '">' +
                  '<input type="checkbox" data-is_accommodation="' + is_accommodation + '" data-discount_price="' + this.discount_price + '" data-price="' + this.price + '" data-group-id="' + groupId + '" id="sluzba-' + this.id + '-' + groupId+ '" name="voucher[services][' + this.id + '][service_id]" value="' + this.id + '">' +
                  '<span class="form__checkbox__icon"></span>' +
                  '<span class="form__checkbox__text">' + this.name + '</span>' +
                  '</label>';
            });
            html += '</div></div></div>';
        });

        $("#custom_autocomplete_wrap").html(html);

        $('.custom_voucher_selected_items .selected-service').each(function(){
            var id = $(this).attr('data-id');
            var groupId = $(this).attr('data-group-id');
            $('#sluzba-' + id + '-' + groupId).prop('checked', true);
            $('#sluzba-' + id + '-' + groupId).trigger('change');
        });

        $('.popup-anchors .btn').hide();

        $('.anchor').each(function(){
            var name = $(this).attr('name');

            $('.' + name).show();
        });

        $('#custom_autocomplete_wrap input').change(function(){
            $selectedService = $('.services-popup-sidebar .custom_voucher_selected_items .selected-service[data-id="' + $(this).attr('value') + '"]');

            if($(this).is(':checked')){
                var countsByPieces = $(this).parents('.custom_autocomplete_group').attr('data-counts-by-pieces');

                if($selectedService.length == 0){
                    var $selectedService = $('<div \
							class="selected-service" \
							data-is_accommodation="' + $(this).attr('data-is_accommodation') + '" \
							data-discount_price="' + $(this).attr('data-discount_price') + '" \
							data-price="' + $(this).attr('data-price') + '" \
							data-id="' + $(this).val() + '" \
							data-group-id="' + $(this).attr('data-group-id') + '"\
							data-counts-by-pieces="' + countsByPieces + '" \
						> \
							<a class="form__checkbox__close"></a> \
							<span class="form__checkbox__text" data-base-name="' + $(this).parent().find('.form__checkbox__text').text() + '">' + $(this).parent().find('.form__checkbox__text').text() + '</span> \
							<span class="selected-service-price">' + $(this).attr('data-price') + '</span> \
							<div class="service-count-cont"> \
								<input class="service-count" id="service-count-' + $(this).val() + '-' + $(this).attr('data-group-id') + '" name="voucher[services][' + $(this).val() + '][' + $(this).attr('data-group-id') + '][count]" type="text" value=""> \
								<div class="form__counter service-count-plus-minus" data-input="service-count-' + $(this).val() + '-' + $(this).attr('data-group-id') + '"> \
									<a class="plus" href=""></a> \
									<a class="minus" href=""></a> \
								</div> \
							</div> \
							<input type="hidden" name="voucher[services][' + $(this).val() + '][' + $(this).attr('data-group-id') + '][service_id]" value="' + $(this).val() + '"> \
							<input type="hidden" name="voucher[services][' + $(this).val() + '][' + $(this).attr('data-group-id') + '][service_group_id]" value="' + $(this).attr('data-group-id') + '"> \
						</div> \
					');

                    $('.services-popup-sidebar .custom_voucher_selected_items').append($selectedService);

                    initializeServiceCount();

                    $selectedService.find('.service-count').trigger('change');
                }
            }
            else {
                $selectedService.remove();
            }

            setServicesCount();
            calculateServicesPrice();
            calculateServicesPricePopup();
        });

    });
}

function calculateServicesPrice(){
    //form .custom_voucher_selected_items

    if($("form .custom_voucher_selected_items_wrap .selected-service").length == 0){
        if (!$("form .custom_voucher_selected_items_wrap").hasClass('hidden')) {
            $("form .custom_voucher_selected_items_wrap").addClass('hidden');
        }
        return;
    }

    var is_accommodation = 0;

    var is_maximus = $('.js-number_services').attr('data-store-id') == 1;

    var total_price = 0;
    var total_price_discount = 0;

    $("form .custom_voucher_selected_items_wrap .selected-service").each(function(){
        var count = $(this).find('.service-count').val();

        if(count == ''){
            count = 1;
        }
        else {
            count = parseInt(count);
        }

        total_price += parseFloat($(this).attr("data-price")) * count;
        total_price_discount += parseFloat($(this).attr("data-discount_price")) * count;
    });

    var total_price_real = total_price_discount;
    var use_discount_price = true;

    if(!is_accommodation){
        if(total_price > 2000){
            total_price_real = total_price_discount;
            use_discount_price = true;
        }
        else {
            total_price_real = total_price;
            use_discount_price = false;
        }
    }

    $("form .custom_voucher_selected_items_wrap .selected-service").each(function(){
        var count = $(this).find('.service-count').val();

        if(count == ''){
            count = 1;
        }
        else {
            count = parseInt(count);
        }

        var price;

        if(use_discount_price){
            price = $(this).attr("data-discount_price") * count;
        }
        else {
            price = $(this).attr("data-price") * count;
        }

        $(this).find('.selected-service-price').text(formatNumber(price) + ' Kč');
    });

    $("#voucherPrice").val(total_price_real).trigger('change');
    $('.js-product_price').text(formatNumber(total_price_real));

    if(userRole == 'salesman' || userRole == 'receptionist' || userRole == 'receptionist_help'){
        if(total_price_real > 0){
            $('.js-product_price').parent().removeClass('hidden')
        }
    }

    $('[name="voucher[original_price]"]').val(total_price_real);
    $('form .total-price').text(formatNumber(total_price_real));

    if(is_accommodation){
        $('.voucher-name').text('Wellness pobyt na míru');
        $('select[name="voucher[subtitle]"]').parent().prev().hide();
        $('#' + $('select[name="voucher[subtitle]"]').attr('id') + '-button').hide();
    }
    else {
        $('.voucher-name').text('Infinit poukaz na míru');
        $('select[name="voucher[subtitle]"]').parent().prev().show();
        $('#' + $('select[name="voucher[subtitle]"]').attr('id') + '-button').show();
    }


    if($(".custom_voucher_selected_items_wrap .selected-service").length > 0){
        $("#prodejni_polozka_field").val(1);
        var validator = $("form.js_form_validate").validate();
        validator.form();
    }else{
        $("#prodejni_polozka_field").val("");
    }

    calculateServicesPricePopup();
}

function calculateServicesPricePopup(){
    //form .custom_voucher_selected_items

    var is_accommodation = 0;

    var is_maximus = $('.js-number_services').attr('data-store-id') == 1;

    var total_price = 0;
    var total_price_discount = 0;

    $(".services-popup-sidebar .selected_area .custom_voucher_selected_items .selected-service").each(function(){
        var count = $(this).find('.service-count').val();

        if(count == ''){
            count = 1;
        }
        else {
            count = parseInt(count);
        }

        total_price += parseFloat($(this).attr("data-price")) * count;
        total_price_discount += parseFloat($(this).attr("data-discount_price")) * count;
    });

    var total_price_real = total_price_discount;

    var use_discount_price = true;

    if(!is_accommodation){
        if(total_price > 2000){
            total_price_real = total_price_discount;
            use_discount_price = true;
        }
        else {
            total_price_real = total_price;
            use_discount_price = false;
        }
    }

    $(".services-popup-sidebar .selected_area .custom_voucher_selected_items .selected-service").each(function(){
        var count = $(this).find('.service-count').val();

        if(count == ''){
            count = 1;
        }
        else {
            count = parseInt(count);
        }

        var price;

        if(use_discount_price){
            price = $(this).attr("data-discount_price") * count;
        }
        else {
            price = $(this).attr("data-price") * count;
        }

        $(this).find('.selected-service-price').text(formatNumber(price) + ' Kč');
    });

    var count = 1;

    if($('input[name="voucher[count]"]').length > 0){
        count = parseInt($('input[name="voucher[count]"]').val());
    }

    $('.services-popup-sidebar .total-price').text(formatNumber(total_price_real * count));
}

function triggerBeforeUnload($bool){
    if($bool){
        window.onbeforeunload = function() {
            if (window._link_was_clicked) {
                return; // abort beforeunload
            }
            return true;
        };
    }else{
        window.onbeforeunload = null;
    }
}

function addSelectPagination($dataTableElement){
    $dataTableElement.on('draw.dt', function(){
        var dataTable = $dataTableElement.DataTable();
        var $wrapper = $dataTableElement.parents('.dataTables_wrapper');

        var $layout = $('<div class="row"><div class="col-md-4"></div><div class="col-md-8"></div></div>');

        var $selectPagination = $('<label>Přejít na stranu <select class="form-control select-pagination"></select></label>');

        $wrapper.find('.paging_simple_numbers').append($layout);
        $wrapper.find('.paging_simple_numbers .pagination').appendTo($('.paging_simple_numbers .row div').last());
        $selectPagination.appendTo($wrapper.find('.paging_simple_numbers .row div').first());

        $selectPagination = $wrapper.find('.paging_simple_numbers .select-pagination');

        var countOfPages = dataTable.page.info().pages;

        if(countOfPages == 0){
            $selectPagination.addClass('hidden');
        }
        else {
            $selectPagination.removeClass('hidden');
        }

        for(var i = 1; i <= countOfPages; i++){
            $selectPagination.append('<option value="' + i + '">' + i + '</option>');
        }

        $selectPagination.val(dataTable.page() + 1);

        $selectPagination.unbind('change').bind('change', function(){
            dataTable.page(parseInt($(this).val()) - 1);
            dataTable.draw('page');
        });

        $('a[data-popup]').unbind().bind('click', function(e){
            e.preventDefault();
            $("#" + $(this).attr("data-popup")).addClass("active");

            $('input[name="cancellation[voucher_batch_ids]"]').val($(this).attr('data-id'));

            var salesman = $(this).attr('data-salesman');
            var cancelDateThreshold = new Date($(this).attr('data-date-cancel'));
            var today = new Date();

            if(salesman == '0'){
                $('.salesman-cancel').hide();

                $('.salesman-cancel input').each(function(){
                    $(this).val('').removeAttr('required');
                });
            }
            else {
                $('.salesman-cancel').show();

                $('.salesman-cancel input:not(#credit_note, #credit_note_check)').each(function(){
                    $(this).attr('required', 'true');
                });

                if (today > cancelDateThreshold) {
                    $('input[id="credit_note_check"]').prop('disabled', 'disabled');
                    $('input[id="credit_note_check"]').parents('label').prop('title', 'Nelze použít po 20. dnu následujícího měsíce od vytvoření.');
                }
            }


        });

        $(".popup.popup--white .js-close").unbind('click').bind('click', function(e){
            e.preventDefault();

            $(this).parent().removeClass('active');
            $(this).parents(".popup__cont").removeClass("active");
        });
    });
}

function addMultipleOperations($dataTableElement){
    $dataTableElement.on('draw.dt', function(e, settings){
        var column, $th;

        for(var key in settings.aoColumns){
            column = settings.aoColumns[key];

            if(column.plugin == 'multiple-operations'){

                $th = $dataTableElement.find('thead tr th:nth-child(' + (key + 1) + ')');

                $th.addClass('multiple-operations');
                $th.html('<label><input type="checkbox" class="multiple-operations-input"> Vybrat vše</label>');

                $input = $th.find('input');

                $th.unbind('click');

                $input.change(function(evt){
                    var $input = $(this);

                    if($input.prop('checked')){
                        //$input.prop('checked', false);
                        $dataTableElement.find('td .multiple-operations-input').prop('checked', true);
                    }
                    else {
                        //$input.prop('checked', true);
                        $dataTableElement.find('td .multiple-operations-input').prop('checked', false);
                    }
                });

                $dataTableElement.find('td:nth-child(' + (key + 1) + ') .multiple-operations-input').change(function(){
                    if(!$(this).is(':checked')){
                        $(this).prop('checked', false);
                        $('th:nth-child(' + (key + 1) + ') .multiple-operations-input').prop('checked', false);
                    }
                });

                var $wrapper = $dataTableElement.parents('.dataTables_wrapper');

                if(typeof column.pluginArgs.options != 'undefined'){

                    var options = '';

                    for(var key2 in column.pluginArgs.options){
                        options += '<option value="' + column.pluginArgs.options[key2].id + '">' + column.pluginArgs.options[key2].title + '</option>';
                    }

                    if($wrapper.find('.multiple-operations-list').length == 0){

                        $(' \
							<div class="multiple-operations-list"> \
								<label>Vybrané přiřadit do <select class="form-control">' + options + '</select>  \
								<a class="btn btn--primary btn--small">uložit</a> \
							</div> \
						').insertAfter($dataTableElement);

                        $wrapper.find('.multiple-operations-list .btn').click(function(){
                            var $checked = $dataTableElement.find('td:nth-child(' + (key + 1) + ') .multiple-operations-input:checked');

                            var rowIds = [];

                            $checked.each(function(){
                                rowIds.push($(this).attr('data-id'));
                            });

                            var optionId = $(this).parent().find('select').val();

                            $.ajax({
                                url: "/services/save-group",
                                method: "post",
                                data: {
                                    rowIds: rowIds,
                                    optionId: optionId
                                },
                                success: function(data){
                                    $dataTableElement.DataTable().draw('page');
                                }
                            });
                        });

                    }
                }

                if(typeof column.pluginArgs.button != 'undefined'){
                    if($wrapper.find('.multiple-operations-list').length == 0){
                        $(' \
							<div class="multiple-operations-list"> \
								<label>Vybrané</label>  \
								' + column.pluginArgs.button + ' \
							</div> \
						').insertAfter($dataTableElement);

                        if(typeof column.pluginArgs.callback != 'undefined'){
                            var callback = column.pluginArgs.callback;
                            callback();
                        }
                    }
                }

                break;
            }
        }

    });
}

function fixActionColumn($dataTableElement){
    $dataTableElement.on('draw.dt', function(){
        $dataTableElement.find('thead th:last-child').removeClass('sorting');

        $('[data-toggle="tooltip"]').tooltip();
    });
}

function addExportButton($dataTableElement){
    $dataTableElement.on('draw.dt', function(){
        var dataTable = $dataTableElement.DataTable();
        var $wrapper = $dataTableElement.parents('.dataTables_wrapper');

        $wrapper.find('.datatable_add_link').find('.datatable-export').remove();
        $wrapper.find('.datatable_add_link').css('textAlign', 'right').append($('<a class="datatable-export btn btn--primary">Export do Excelu</a>'));

        $wrapper.find('.datatable-export').click(function(){
            let paramSeparator = '?';
            if (dataTable.ajax.url().indexOf('?') > -1) {
                paramSeparator = '&';
            }
            dataTable.ajax.url(dataTable.ajax.url() + paramSeparator + 'export=csv');
            dataTable.draw('page');

        });

        $dataTableElement.on('xhr.dt', function(){
            var dataTable = $dataTableElement.DataTable();
            dataTable.ajax.url(dataTable.ajax.url().split('?')[0]);

            response = dataTable.ajax.json();

            if(typeof response.export != 'undefined'){
                window.open(response.export);
            }
        });
    });
}

function initializeServiceCount(){
    $('.service-count').unbind('change');
    $('.service-count').bind('change', function(){
        var val = $(this).val();

        if(val == ''){

        }
        else {
            val = parseInt(val);

            if(isNaN(val)){
                val = '';
            }
            else if(val > 10){
                val = 10;
            }
            else if(val <= 0){
                val = '';
            }
        }

        $(this).val(val);

        var $nameElement = $(this).parent(".service-count-cont").prev().prev();
        var name = $nameElement.attr('data-base-name');

        var $selectedService = $(this).parents('.selected-service');
        var countsByPieces = $selectedService.attr('data-counts-by-pieces') == '1';

        var inflection = {
            1: 'osobu',
            2: 'osoby', 3: 'osoby', 4: 'osoby',
            5: 'osob', 6: 'osob', 7: 'osob', 8: 'osob', 9: 'osob', 10: 'osob'
        };

        if(val != ''){
            if(countsByPieces){
                name = val + 'x ' + name;
            }
            else {
                name = name + ' pro ' + val + ' ' + inflection[val];
            }
        }

        $nameElement.text(name);

        calculateServicesPrice();
        calculateServicesPricePopup();
    });

    $('.service-count').trigger('change');

    $('.form__counter.service-count-plus-minus .plus, .form__counter.service-count-plus-minus .minus').unbind('click').bind('click', function(e){
        e.preventDefault();

        var $input = $('.service-count[id="' + $(this).parent('.form__counter').attr('data-input') + '"]');
        var value = $input.val();

        if(value == ''){
            value = 0;
        }
        else {
            value = parseInt(value);
        }

        if($(this).hasClass('plus')){
            value += 1;
        }
        else if($(this).hasClass('minus')){
            value -= 1;
        }

        $input.each(function(){
            $(this).val(value).trigger('change');
        });
    });
}

$(document).ready(function(){
    calculateServicesPrice();
    initializeServiceCount();

    //věnování počítání znaků
    $("body").on('change keyup',".js-textarea-count", function(){
        var text = $(this).val();
        var chars = $(this).val().length;
        var maxChars = parseInt($(this).attr('maxlength'));

        $(this).siblings('.form__textarea__chars').children('span').text(chars);
    });

    $('input[name="voucher[count]"]').change(function(){

        $('.total-count').text($(this).val());

        if($(this).val() == '0' || parseInt($(this).val()) > 4){
            $('.total-count-str').text('poukazů');
        }
        else if($(this).val() == '1'){
            $('.total-count-str').text('poukaz');
        }
        else if($(this).val() == '2' || $(this).val() == '3' || $(this).val() == '4'){
            $('.total-count-str').text('poukazy');
        }

        //calculateServicesPrice();
    });

    window._link_was_clicked = false;

    $(".add-voucher .btn, form button, .form__nahled a, .voucher_page a:not(.step), .voucher_page button").click(function(){
        window._link_was_clicked = true;
    });

    $("a, button").not(".add-voucher .btn, form button, .form__nahled a, .voucher_page a:not(.step), .voucher_page button").click(function(){
        window._link_was_clicked = false;
    });

    if($(".voucher-steps").length > 0 && $(".voucher_page").length > 0 && $('.success-send').length == 0){
        triggerBeforeUnload(true);
    }

    var Latinise={};Latinise.latin_map=
        {"Á":"A","Č":"C","Ď":"D","Ě":"E","É":"E","Í":"I","Ň":"N","Ó":"O","Ř":"R","Š":"S","Ť":"T","Ú":"U","Ů":"U","Ý":"Y","Ž":"Z","á":"a","č":"c","ď":"d","ě":"e","é":"e","í":"i","ň":"n","ó":"o","ř":"r","š":"s","ť":"t","ú":"u","ů":"u","ý":"y","ž":"z"};
    var latinise = function(str){
        return str.replace(/[^A-Za-z0-9\[\] ]/g, function(a){
            return Latinise.latin_map[a]||a;
        });
    };

    var insertString = function(toInsert, insertInto, index) {
        return insertInto.slice(0, index) + toInsert + insertInto.slice(index);
    }

    var surroundByTags = function(startingTag, closingTag, surroundIn, start, len) {
        var startTagLen = startingTag.length;
        var withStartingTag = insertString(startingTag, surroundIn, start);
        return insertString(closingTag, withStartingTag, start + startTagLen + len);
    };

    var highlight = function(data, search){
        if(typeof data == 'undefined' || data == null){
            return '';
        }

        var found;
        var searches = search.split(" ");
        var latinisedSearches = latinise(search.toLowerCase()).split(" ");

        searches = searches.sort(function(a, b){
            return $.trim(b).length - $.trim(a).length;
        });

        var dataParts = data.split(" ");
        var latinisedDataParts = latinise(data.toLowerCase()).split(" ");
        data = '';
        var found = false;

        var startingTag = '<mark>';
        var closingTag = "</mark>";

        for(var key in latinisedDataParts){
            found = false;
            for(var key2 in latinisedSearches){
                found = false;
                indexFound = latinisedDataParts[key].indexOf(latinisedSearches[key2]);
                if(indexFound > -1) {
                    data += surroundByTags(startingTag, closingTag, dataParts[key], indexFound, latinisedSearches[key2].length) + "&nbsp;";
                    found = true;
                    break
                }
            }
            if(!found) {
                data += dataParts[key]+"&nbsp;";
            }
        }

        return $.trim(data);
    };

    var baseCellRender = function(data, type, row, meta){
        var search = $('.dataTables_filter input[type="search"]').val().trim();

        if(search != ''){
            data = highlight(data, search);
        }

        return data;
    }

    //users
    $('#usersDataTable').DataTable({
        ajax: '/users/data-table',
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'email',
                title: translations['E-mail'],
                render: baseCellRender
            },
            {
                data: 'name',
                title: translations['Název'],
                render: baseCellRender
            },
            {
                data: 'surname',
                title: translations['Příjmení'],
                render: baseCellRender
            },
            {
                data: 'store_name',
                title: translations['Pobočka'],
                render: baseCellRender
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/users/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a></div>';
                }
            }
        ],
        initComplete: function(settings, json){
            $(".js_confirm_delete").click(function(e){
                e.preventDefault();
                var result = confirm('Opravdu chcete smazat uživatele ' + $(this).attr('data-confirm-title') + '?');
                if (result) {
                    //Logic to delete the item
                    window.location.href = $(this).attr('href');
                }
            });
        },
        language: {
            search: translations['Hledat uživatele'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#usersDataTable'));

    fixActionColumn($('#usersDataTable'));



    $('#services-groupsDataTable').DataTable({
        ajax: '/services-groups/data-table',
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'name',
                title: translations['Název'],
                render: baseCellRender
            },
            {
                data: 'is_accommodation',
                title: translations['Ubytování'],
                render: function(data, type, row, meta){
                    if(data == 0){
                        return translations['Ne'];
                    }else if(data == 1){
                        return translations['Ano'];
                    }
                }
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/services-groups/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a><a class="alone_icon js_confirm_delete" href="/services-groups/delete/' + row.id + '"><i class="icon icon-trash"></i></a></div>';
                }
            }
        ],
        language: {
            search: translations['Hledat skupinu'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#services-groupsDataTable'));

    fixActionColumn($('#services-groupsDataTable'));


    $('#servicesDataTable').DataTable({
        ajax: '/services/data-table',
        stateSave: true,
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1,0] } ],
        responsive: true,
        columns: [
            {
                title: '',
                plugin: 'multiple-operations',
                pluginArgs: {
                    options: servicesGroups
                },
                render: function(data, type, row, meta){
                    return '<input type="checkbox" class="multiple-operations-input" data-id="' + row.id + '">';
                }
            },
            {
                data: 'service_group_name',
                title: translations['Skupina'],
                render: baseCellRender
            },
            {
                data: 'name',
                title: translations['Název'],
                render: baseCellRender
            },
            {
                data: 'member_pro_name',
                title: translations['Member Pro název'],
                render: baseCellRender
            },
            {
                data: 'store_name',
                title: translations['Pobočka'],
                render: baseCellRender
            },
            {
                data: 'people_count',
                title: translations['Počet osob'],
                render: baseCellRender
            },
            {
                data: 'nights_count',
                title: translations['Počet nocí'],
                render: baseCellRender
            },
            {
                data: 'price',
                title: translations['Cena'],
                render: function(data, type, row, meta){
                    if(data == 0 || data == null){
                        return '--&nbsp;neuvedeno&nbsp;--';
                    }
                    return formatNumber(data) + ' Kč';
                }
            },
            {
                data: 'discount_price',
                title: translations['Infinit+'],
                render: function(data, type, row, meta){
                    if(data == 0 || data == null){
                        return '--&nbsp;neuvedeno&nbsp;--';
                    }
                    return formatNumber(data) + ' Kč';
                }
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/services/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a></div>';
                }
            }
        ],
        language: {
            search: translations['Hledat prodejní položku'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#servicesDataTable'));

    addMultipleOperations($('#servicesDataTable'));

    fixActionColumn($('#servicesDataTable'));



    $('#servicesDataTableNoGroup').DataTable({
        ajax: '/services/data-table-no-group',
        stateSave: true,
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                title: '',
                plugin: 'multiple-operations',
                pluginArgs: {
                    options: servicesGroups
                },
                render: function(data, type, row, meta){
                    return '<input type="checkbox" class="multiple-operations-input" data-id="' + row.id + '">';
                }
            },
            {
                data: 'service_group_name',
                title: translations['Skupina'],
                render: baseCellRender
            },
            {
                data: 'name',
                title: translations['Název'],
                render: baseCellRender
            },
            {
                data: 'member_pro_name',
                title: translations['Member Pro název'],
                render: baseCellRender
            },
            {
                data: 'store_name',
                title: translations['Pobočka'],
                render: baseCellRender
            },
            {
                data: 'price',
                title: translations['Cena'],
                render: function(data, type, row, meta){
                    return data + ' Kč';
                }
            },
            {
                data: 'discount_price',
                title: translations['Infinit+'],
                render: function(data, type, row, meta){
                    return data + ' Kč';
                }
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/services/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a></div>';
                }
            }
        ],
        language: {
            search: translations['Hledat prodejní položku'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#servicesDataTableNoGroup'));

    addMultipleOperations($('#servicesDataTableNoGroup'));

    fixActionColumn($('#servicesDataTableNoGroup'));

    var vouchersDataTableColumns = [];

    vouchersDataTableColumns.push({
        data: 'created_timestamp',
        title: translations['Datum'],
        render: function(data, type, row, meta){
            var date = new Date(data.date);
            return date.toLocaleDateString("cs-CZ") + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        }
    });

    vouchersDataTableColumns.push({
        data: 'code',
        title: translations['Kód'],
        render: function(data, type, row, meta){

            if(row.user_role_name == 'Obsluha e-shopu'){
                return '';
            }

            var search = $('.dataTables_filter input[type="search"]').val().trim();

            var codes = data.split(',');

            if(codes.length < 2){
                return '<span>' + highlight(data, search) + '</span>';
            }

            var codesHtml = '';

            var i = 0;
            for(var key in codes){
                codesHtml += codes[key];

                if(key != codes.length - 1){
                    codesHtml += ', ';
                }

                i++;

                if(i >= 3){
                    codesHtml += '<br>';
                    i = 0;
                }
            }

            return '<a class="has-tooltip has-link" href="/voucher/detail?voucherBatchId=' + row.id + '" data-toggle="tooltip" data-html="true" title="' + codesHtml + '">' + highlight(codes[0], search) + '&nbsp;(' + codes.length + ')</a>';
        }
    });

    vouchersDataTableColumns.push({
        data: 'name',
        title: translations['Název'],
        render: baseCellRender
    });

    vouchersDataTableColumns.push({
        data: 'user_name_surname',
        title: translations['Vytvořil'],
        render: baseCellRender
    });

    if(userRole == 'admin'){
        vouchersDataTableColumns.push({
            data: 'user_role_name',
            title: translations['Role'],
            render: baseCellRender
        });
    }

    if (userRole !== 'employee') {
        vouchersDataTableColumns.push({
            data: 'store_abbr',
            title: translations['Pobočka'],
            render: baseCellRender
        });
    } else {
        vouchersDataTableColumns.push({
            data: 'store_abbr',
            title: translations['Poukaz'],
            render: baseCellRender
        });
        vouchersDataTableColumns.push({
            data: 'work_at_store_abbr',
            title: translations['Pobočka'],
            render: baseCellRender
        });
    }

    vouchersDataTableColumns.push({
        data: 'price',
        title: translations['Cena'],
        render: function(data, type, row, meta){
            if(row.original_price != data && row.original_price != 0){
                return '<span class="warning-highlight">' + formatNumber(data) + ' Kč</span>';
            }

            if(data == 0 || data == null){
                return '--&nbsp;neuvedeno&nbsp;--';
            }
            return formatNumber(data) + ' Kč';
        }
    });

    if(userRole == 'employee'){
        vouchersDataTableColumns.push({
            data: 'employee_name',
            title: translations['Jméno zaměstnance'],
            render: baseCellRender
        });

        vouchersDataTableColumns.push({
            data: 'workplacement_time',
            title: translations['Délka zaměstnání'],
            render: function(data, type, row, meta){
                if (row.employee_giving_reason !== '' && (row.type === 1 || row.type === 2)) {
                    return '<a class="has-tooltip" data-toggle="tooltip" data-html="true" title="' + row.employee_giving_reason + '">' + row.workplacement_time + '</a>';
                }
                else {
                    return '<span>' + row.workplacement_time + '</span>';
                }
            }
        });

        vouchersDataTableColumns.push({
            data: 'validity_date',
            title: translations['Datum platnosti'],
            render: function(data){
                if (data === null) {
                    return '-';
                }
                var date = new Date(data.date);
                return date.toLocaleDateString("cs-CZ");
            }
        });
    }

    if(userRole == 'admin'
        || userRole == 'receptionist'
        || userRole == 'receptionist_help'
        || userRole == 'salesman'){
        vouchersDataTableColumns.push({
            data: 'original_price',
            title: translations['Původní cena'],
            render: function(data, type, row, meta){
                if(data == 0 || data == null){
                    return '--&nbsp;neuvedeno&nbsp;--';
                }
                else {
                    return formatNumber(data) + ' Kč';
                }
            }
        });
    }

    if(userRole == 'marketing_consultant' || userRole == 'admin'){
        vouchersDataTableColumns.push({
            data: 'registration_sheet_label',
            title: translations['Evidenční list'],
            render: function(data, type, row, meta){
                if(row.registration_sheet_id > 0){
                    //data = data.replace(/ /g, '&nbsp;');

                    var search = $('.dataTables_filter input[type="search"]').val().trim();

                    if(search != ''){
                        var content = '<a href="/voucher/registration-sheet-pdf?voucher=' + row.id + '" target="_blank">' + highlight(data, search) + '</a>';
                    }
                    else {
                        var content = '<a href="/voucher/registration-sheet-pdf?voucher=' + row.id + '" target="_blank">' + data + '</a>';
                    }

                    return content;
                }
                else {
                    return '';
                }
            }
        });
    }

    if(userRole == 'marketing_consultant'){
        vouchersDataTableColumns.push({
            data: 'ceo_name',
            title: translations['Jednatel'],
            render: baseCellRender
        });

        vouchersDataTableColumns.push({
            data: 'substitute_code',
            title: translations['Prodloužení'],
            render: baseCellRender
        });

        vouchersDataTableColumns.push({
            data: 'duplicate_code',
            title: translations['Duplikát'],
            render: baseCellRender
        });
    }

    if(userRole == 'salesman' || userRole == 'admin'){
        vouchersDataTableColumns.push({
            data: 'invoice_number',
            title: translations['Faktura'],
            render: baseCellRender
        });

        vouchersDataTableColumns.push({
            data: 'invoice_issue_date',
            title: translations['Datum faktury'],
            render: function(data){
                if (data === null) {
                    return '-';
                }
                var date = new Date(data.date);
                return date.toLocaleDateString("cs-CZ");
            }
        });
    }

    if(userRole != 'receptionist' && userRole != 'receptionist_help' && userRole != 'eshop_manager'){
        vouchersDataTableColumns.push({
            data: 'cancel_label',
            title: translations['Stornováno'],
            render: function(data, type, row, meta){
                var cancelReason = '';
                var parts = row.cancel_reason.split('|||');

                for(var key in parts){
                    if(parts[key] != ''){
                        cancelReason = parts[key];
                        break;
                    }
                }

                var cancelReason2 = '';
                var parts = row.cancel_reason2.split('|||');

                for(var key in parts){
                    if(parts[key] != ''){
                        cancelReason2 = parts[key];
                        break;
                    }
                }

                if(row.user_role_name == 'Recepční' || row.user_role_name == 'Admin'){
                    return '';
                }

                if(cancelReason != ''){
                    if(userRole == 'salesman' || userRole == 'eshop_manager'){
                        if(row.user_role_name == 'Obchod'){
                            cancelReason += '<br>' + cancelReason2;
                        }
                    }

                    return '<a class="has-tooltip" data-toggle="tooltip" data-html="true" title="' + cancelReason + '">' + data + '</a>';
                }
                else {
                    return '<span class="like-link">' + data + '</span>';
                }
            }
        });
    }

    vouchersDataTableColumns.push({
        data: 'sent_to_member_pro',
        title: 'Odesláno do MemberPro',
        render: function(data, type, row) {
            /*
            var values = row.item_sent_to_member_pro.split(',').map(value => value.trim());
            var allItemsSent = values.every(value => value === "1");
            */

            var filterSVG = (row.cancel === -1 || row.cancel === 1)
                ? "filter: invert(39%) sepia(100%) saturate(5000%) hue-rotate(0deg);"
                : "filter: invert(1);";

            var iconType = (data == 1) ? "check" : "cross";
            var filterStyle = (iconType === "check") ? filterSVG : "";

            return `<span class="sent-to-member-pro ${iconType}" 
                        style="display: block;
                               background-image: url(/assets/src/icons/${iconType}.svg);
                               background-repeat: no-repeat;
                               width: 20px;
                               height: 20px;
                               ${filterStyle}">
                    </span>`;
        }
    });

    vouchersDataTableColumns.push({
        title: translations['Akce'],
        render: function(data, type, row, meta){
            var akce = ' \
    			<div class="akce" style="width: 210px;"> \
    		';

            if(row.cancel != 1){
                akce += ' \
    				<div class="row"> \
    					<a style="margin-right: 5px;" href="/voucher/voucher-pdf?voucherBatch=' + row.id + '&graphics=1" target="_blank">K TISKU</a> \
    					<a style="margin-right: 5px;" href="/voucher/voucher-pdf?voucherBatch=' + row.id + '&graphics=1&format=a4" target="_blank">E-POUKAZ</a> \
    				</div> \
    				<div class="row">\
    				    <a style="margin-right: 5px;" href="/voucher/voucher-zip?voucherBatch=' + row.id + '&graphics=1" target="_blank">ZIP-TISK</a> \
    				    <a style="margin-right: 5px;" href="/voucher/voucher-zip?voucherBatch=' + row.id + '&graphics=1&format=a4" target="_blank">ZIP-E-POUKAZ</a> \
    				</div>\
    			';
            }

            var newLine = false;

            if(row.code.split(',').length > 1 && row.user_role_name != 'Recepční' && row.user_role_name != 'Admin'){
                akce += '<div class="row">';

                akce += '<a style="margin-right: 5px;" href="/voucher/detail?voucherBatchId=' + row.id + '">' + translations['detail'] + '</a>';
                newLine = true;
            }

            if(row.cancel != 1 && row.user_role_name != 'Recepční' && row.user_role_name != 'Admin' && row.user_role_name != 'Obsluha e-shopu'){
                if(newLine){
                    akce += ' \
    				    <a data-popup="stornoPopup" data-id="' + row.id + '" data-salesman="' + (userRole === 'salesman' ? '1' : '0')  + '" data-date-cancel="'+row.date_next_month_cancel+'">' + translations['stornovat'] + '</a> \
    			    ';
                } else {
                    akce += ' \
                        <div class="row"><a data-popup="stornoPopup" data-id="' + row.id + '" data-salesman="' + (userRole === 'salesman' ? '1' : '0')  + '" data-date-cancel="'+row.date_next_month_cancel+'">' + translations['stornovat'] + '</a></div> \
                    ';
                }

                newLine = true;
            }

            if(newLine){
                akce += '</div>';
            }

            var resendController = '';

            if (row.is_reissue !== 1) {
                if (row.type === 1) {
                    resendController = 'alfax-voucher';
                } else if (row.type === 2) {
                    resendController = 'custom-voucher';
                } else if (row.type === 3) {
                    resendController = 'price-voucher';
                } else if (row.type === 4) {
                    resendController = 'benefit-voucher';
                }
            }

            if (resendController !== '') {
                var values = row.item_sent_to_member_pro.split(',');
                var allItemsSent = values.every(value => value.trim() === "1");

                akce += '\
                    <div class="row"> \
                        <a class="btn-resend' + ((row.sent_to_member_pro == 1 && allItemsSent) ? ' disabled' : '') + '" style="margin-right: 5px;" href="/' + resendController + '/resend-to-member-pro?voucherBatch=' + row.id + '">ZNOVU ODESLAT DO MEMBERPRO</a> \
                    </div>';
            }

            akce += '</div>';

            return akce;
        }
    });

    $('#vouchersDataTable').DataTable({
        ajax: '/voucher/data-table',
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: vouchersDataTableColumns,
        language: {
            search: translations['Hledat poukaz'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#vouchersDataTable'));
    fixActionColumn($('#vouchersDataTable'));
    addExportButton($('#vouchersDataTable'));
    addExportButton($('#vouchersItemsDataTable'));

    var isReceptionist = $('#vouchersItemsDataTable').attr('data-receptionist') == '1';
    var vouchersItemsDataTableColumns = [];

    if(!isReceptionist){
        vouchersItemsDataTableColumns.push({
            title: '',
            plugin: 'multiple-operations',
            pluginArgs: {
                button: '<a class="btn btn--primary btn--small">stornovat</a>',
                callback: function(){
                    $('.multiple-operations-list .btn').click(function(){
                        var $checked = $('#vouchersItemsDataTable .multiple-operations-input:checked');

                        var rowIds = [];

                        $checked.each(function(){
                            rowIds.push($(this).attr('data-id'));
                        });

                        if(rowIds.length > 0){
                            $('input[name="cancellation[voucher_items_ids]"]').val(rowIds.join(','));

                            $("#stornoPopup").addClass("active");
                        }
                    });
                }
            },
            render: function(data, type, row, meta){
                if(row.cancel == 1){
                    return '';
                }
                else {
                    return '<input type="checkbox" class="multiple-operations-input" data-id="' + row.id + '">';
                }
            }
        });
    }

    vouchersItemsDataTableColumns.push({
        data: 'created_timestamp',
        title: translations['Datum'],
        render: function(data, type, row, meta){
            var date = new Date(data.date);
            return date.toLocaleDateString("cs-CZ") + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        }
    });

    vouchersItemsDataTableColumns.push({
        data: 'code',
        title: translations['Kód'],
        render: baseCellRender
    });

    vouchersItemsDataTableColumns.push({
        data: 'name',
        title: translations['Název'],
        render: baseCellRender
    });

    vouchersItemsDataTableColumns.push({
        data: 'price',
        title: translations['Cena'],
        render: function(data, type, row, meta){
            return data + ' Kč';
        }
    });

    if(!isReceptionist){
        vouchersItemsDataTableColumns.push({
            data: 'cancel_label',
            title: translations['Stornováno'],
            render: baseCellRender
        });

        vouchersItemsDataTableColumns.push({
            data: 'cancel_user_name',
            title: translations['Stornoval'],
            render: function(data, type, row, meta){
                if(row.cancel_user_name == ''){
                    return '';
                }

                if(row.cancel_reason != ''){
                    return '<a data-toggle="tooltip" data-html="true" title="' + row.cancel_reason + '">' + row.cancel_user_name + '</a>';
                }

                return '<span>' + row.cancel_user_name + '</span>';
            }
        });

        vouchersItemsDataTableColumns.push({
            data: 'cancel_timestamp',
            title: translations['Datum storna'],
            render: baseCellRender
        });

        if (userRole === 'salesman') {
            vouchersItemsDataTableColumns.push({
                data: 'credit_note_number',
                title: translations['Dobropis'],
                render: baseCellRender
            });

            vouchersItemsDataTableColumns.push({
                data: 'cancel_type',
                title: translations['Typ'],
                render: baseCellRender
            });
        }
    }

    vouchersItemsDataTableColumns.push({
        data: 'sent_to_member_pro',
        title: 'Odesláno do MemberPro',
        render: function(data, type, row) {
            if (row.item_sent_to_member_pro == 1) {
                return '<span class="sent-to-member-pro check" style="display: block;background-image: url(/assets/src/icons/check.svg);background-repeat: no-repeat;filter: invert(1);width: 20px;height: 20px;"></span>';
            } else {
                return '<span class="sent-to-member-pro cross" style="display: block;background-image: url(/assets/src/icons/cross.svg);background-repeat: no-repeat;width: 20px;height: 20px;"></span>';
            }
        }
    });

    vouchersItemsDataTableColumns.push({
        title: translations['Akce'],
        render: function(data, type, row, meta){

            var akce = ' \
    			<div class="akce" style="width: 210px;"> \
    		';

            if(row.cancel != 1){
                akce += ' \
    				<div class="row"> \
    					<a href="/voucher/voucher-pdf?voucherItem=' + row.id + '&graphics=1" target="_blank">PDF</a> \
    				</div> \
    			';
            }

            var newLine = false;

            if(row.cancel != 1 && row.user_role_name != 'Recepční' && row.user_role_name != 'Obsluha e-shopu' && !isReceptionist){
                if(!newLine){
                    //akce += '<div class="row"><div class="col-md-4 col-md-offset-3"></div>';
                }

                akce += ' \
    				<div class="row"><a data-popup="stornoPopup" data-id="' + row.id + '" data-salesman="' + (userRole === 'salesman' ? '1' : '0')  + '" data-date-cancel="'+row.date_next_month_cancel+'">' + translations['stornovat'] + '</a></div> \
    			';
                newLine = true;
            }

            if(newLine){
                akce += '</div>';
            }

            akce += '</div>';

            return akce;
        }
    });


    $('#vouchersItemsDataTable').DataTable({
        ajax: {
            url: '/voucher/items-data-table',
            type: 'GET',
            data: {
                voucherBatchId: $('#vouchersItemsDataTable').attr('data-batch-id')
            }
        },
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: vouchersItemsDataTableColumns,
        language: {
            search: translations['Hledat poukaz'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#vouchersItemsDataTable'));

    if(!isReceptionist){
        addMultipleOperations($('#vouchersItemsDataTable'));
    }

    fixActionColumn($('#vouchersItemsDataTable'));

    $('#patternsDataTable').DataTable({
        ajax: '/benefit-voucher/data-table',
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'workplacement_name',
                title: translations['Pracovní pozice'],
                render: baseCellRender
            },
            {
                data: 'workplacement_time_name',
                title: translations['Délka zaměstnání'],
                render: baseCellRender
            },
            {
                data: 'work_at_store_name',
                title: translations['Pobočka zaměstnání'],
                render: baseCellRender
            },
            {
                data: 'apply_at_store_name',
                title: translations['Pobočka poukazu'],
                render: baseCellRender
            },
            {
                data: 'alfax_product_name',
                title: translations['Produkt'],
                render: baseCellRender
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/benefit-voucher/pattern/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a><a class="alone_icon js_confirm_delete" href="/benefit-voucher/pattern/delete/' + row.id + '" data-confirm-title="' + row.alfax_product_name + '"><i class="icon icon-trash"></i></a></div>';
                }
            }
        ],
        initComplete: function(settings, json){
            $(".js_confirm_delete").click(function(e){
                e.preventDefault();
                var result = confirm('Opravdu chcete smazat vzor s produktem ' + $(this).attr('data-confirm-title') + '?');
                if (result) {
                    //Logic to delete the item
                    window.location.href = $(this).attr('href');
                }
            });
        },
        language: {
            search: translations['Hledat vzor poukazu'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#patternsDataTable'));

    fixActionColumn($('#patternsDataTable'));

    addExportButton($('#patternsDataTable'));






    $('#storesDataTable').DataTable({
        ajax: '/stores/data-table',
        stateSave: true,
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'name',
                title: translations['Název'],
                render: baseCellRender
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/stores/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a></div>';
                }
            }
        ],
        language: {
            search: translations['Hledat pobočku'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#storesDataTable'));

    fixActionColumn($('#storesDataTable'));





    $('#ceosDataTable').DataTable({
        ajax: '/ceos/data-table',
        stateSave: true,
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'name',
                title: translations['Jméno'],
                render: baseCellRender
            },
            {
                data: 'surname',
                title: translations['Příjmení'],
                render: baseCellRender
            },
            {
                data: 'active',
                title: translations['Aktivní'],
                render: function(data, type, row, meta){
                    if(data == 0){
                        return translations['Ne'];
                    }else if(data == 1){
                        return translations['Ano'];
                    }
                }
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/ceos/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a></div>';
                }
            }
        ],
        initComplete: function(settings, json){
            $(".js_confirm_delete").click(function(e){
                e.preventDefault();
                var result = confirm('Opravdu chcete smazat jednatele ' + $(this).attr('data-confirm-title') + '?');
                if (result) {
                    //Logic to delete the item
                    window.location.href = $(this).attr('href');
                }
            });
        },
        language: {
            search: translations['Hledat jednatele'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#ceosDataTable'));

    fixActionColumn($('#ceosDataTable'));




    $('#workplacementsDataTable').DataTable({
        ajax: '/workplacements/data-table',
        stateSave: true,
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'name',
                title: translations['Název'],
                render: baseCellRender
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/workplacements/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a></div>';
                }
            }
        ],
        initComplete: function(settings, json){
            $(".js_confirm_delete").click(function(e){
                e.preventDefault();
                var result = confirm('Opravdu chcete smazat pracovní pozici ' + $(this).attr('data-confirm-title') + '?');
                if (result) {
                    //Logic to delete the item
                    window.location.href = $(this).attr('href');
                }
            });
        },
        language: {
            search: translations['Hledat pracovní pozici'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#workplacementsDataTable'));

    fixActionColumn($('#workplacementsDataTable'));





    $('#workplacementTimesDataTable').DataTable({
        ajax: '/workplacement-times/data-table',
        stateSave: true,
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'name',
                title: translations['Název'],
                render: baseCellRender
            },
            {
                title: translations['Akce'],
                render: function(data, type, row, meta){
                    return '<div class="akce"><a class="" href="/workplacement-times/edit/' + row.id + '"><i class="icon icon-pen"></i>' + translations['Editovat'] + '</a><a class="alone_icon js_confirm_delete" href="/workplacement-times/delete/' + row.id + '" data-confirm-title="' + row.name + '"><i class="icon icon-trash"></i></a></div>';
                }
            }
        ],
        initComplete: function(settings, json){
            $(".js_confirm_delete").click(function(e){
                e.preventDefault();
                var result = confirm('Opravdu chcete smazat délku zaměstnání ' + $(this).attr('data-confirm-title') + '?');
                if (result) {
                    //Logic to delete the item
                    window.location.href = $(this).attr('href');
                }
            });
        },
        language: {
            search: translations['Hledat délku zaměstnání'] + ' ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    addSelectPagination($('#workplacementTimesDataTable'));

    fixActionColumn($('#workplacementTimesDataTable'));

    $('#fromMemberProDataTable').DataTable({
        ajax: '/test/data-table-from-member-pro',
        stateSave: true,
        processing: true,
        serverSide: true,
        order: [],
        dataSrc: 'data',
        dom: '<"row"<"col-md-4"f><"col-md-4 col-md-offset-4 col-lg-3 col-lg-offset-5 datatable_add_link">>tp<"clear">',
        columnDefs: [ { orderable: false, targets: [-1] } ],
        responsive: true,
        columns: [
            {
                data: 'ID',
                title: 'ID',
                // render: baseCellRender
            },
            {
                data: 'CISLO_OD',
                title: 'CISLO_OD',
                // render: baseCellRender
            },
            {
                data: 'CISLO_DO',
                title: 'CISLO_DO',
                // render: baseCellRender
            },
            {
                data: 'ID_MAT',
                title: 'ID_MAT',
                // render: baseCellRender
            },
            {
                data: 'UCEL',
                title: 'UCEL',
                // render: baseCellRender
            },
            {
                data: 'ZAPLACENO',
                title: 'ZAPLACENO',
                // render: baseCellRender
            },
            {
                data: 'DATUM_VYDANI',
                title: 'DATUM_VYDANI',
                // render: baseCellRender
            },
            {
                data: 'DATUM_PLATNOSTI',
                title: 'DATUM_PLATNOSTI',
                // render: baseCellRender
            },
            {
                data: 'CENA_J',
                title: 'CENA_J',
                // render: baseCellRender
            },
            {
                data: 'DPH_SAZBA',
                title: 'DPH_SAZBA',
                // render: baseCellRender
            },
            {
                data: 'TYP',
                title: 'TYP',
                // render: baseCellRender
            },
            {
                data: 'POCET',
                title: 'POCET',
                // render: baseCellRender
            },
            {
                data: 'POBOCKA',
                title: 'POBOCKA',
                // render: baseCellRender
            },
            {
                data: 'VYDAL_KDO',
                title: 'VYDAL_KDO',
                // render: baseCellRender
            },
            {
                data: 'VYDAL_KDE',
                title: 'VYDAL_KDE',
                // render: baseCellRender
            },
            {
                data: 'VYDAL_KDY',
                title: 'VYDAL_KDY',
                // render: baseCellRender
            },
            {
                data: 'NAZEV_POUKAZU',
                title: 'NAZEV_POUKAZU',
                // render: baseCellRender
            },
            {
                data: 'ID_RADEK',
                title: 'ID_RADEK',
                // render: baseCellRender
            },
            {
                data: 'STORNO',
                title: 'STORNO',
                // render: baseCellRender
            },
            {
                data: 'ID1',
                title: 'ID1',
                // render: baseCellRender
            },
            {
                data: 'VENOVANI',
                title: 'VENOVANI',
                // render: baseCellRender
            },
            {
                data: 'CISLO_FAKTURY',
                title: 'CISLO_FAKTURY',
                // render: baseCellRender
            },
            {
                data: 'POBOCKA1',
                title: 'POBOCKA1',
                // render: baseCellRender
            }
        ],
        language: {
            search: 'Hledat ',
            processing: translations['Načítání dat...'],
            zeroRecords: translations['Nebyl nalezen žádný záznam'],
            paginate: {
                previous: 'Předchozí',
                next: 'Další'
            }
        },
        pagingType: 'simple_numbers'
    });

    $(".datatable_add_link").html($(".js-btn_to_add"));

    //MENU
    $('.js-menu_open, .js-menu_close, .menu_overlay').click(function(e){
        e.preventDefault();
        $(".header__menu").toggleClass("show");
        $(".menu_overlay").toggleClass("show");
    });

    //přepínaní tabů pomocí radio
    $('input[data-toggle="custom_tab"]').click(function () {
        $(this).tab('show');
    });

    //přestylované selecty jquery ui
    $(".select-box select").selectmenu({
        select: function(event, ui){
            $(this).trigger('change');
        }
    });

    //radio button checked custom style
    $(".form__radio input").change(function(e){
        $('.form__radio input[name="' + $(this).attr("name") + '"]').parent(".form__radio").removeClass("checked");
        $(this).parent(".form__radio").addClass("checked");
    });

    //form__counter plus a minus
    $(".form__counter:not(.service-count-plus-minus) .plus, .form__counter:not(.service-count-plus-minus) .minus").click(function(e){
        e.preventDefault();
        var $input = $("#" + $(this).parent(".form__counter").attr("data-input"));
        if($input.val() == ""){
            $input.val(0);
        }
        //plus or minus
        if($input.val() > 1){

            // plus and minus
            if($(this).hasClass("plus")){
                $input.val(parseInt($input.val())+1);
            }
            if($(this).hasClass("minus")){
                $input.val(parseInt($input.val())-1);
            }
        }else{
            //only plus
            if($(this).hasClass("plus")){
                $input.val(parseInt($input.val())+1);
            }
        }

        //check if disabled
        if($input.val() > 1){
            $(this).parent(".form__counter").children(".minus").removeClass("disabled");
        }else{
            $(this).parent(".form__counter").children(".minus").addClass("disabled");
        }

        //textarea voucher items
        if($("#venovani2").length > 0){
            var html = "";
            if($input.val() >= 1){
                for (var i = 0; i < $input.val(); i++){
                    var cur_val = "";
                    if($('#venovani2 textarea[name="voucher[items][' + i + '][dedication]"]').val() !== undefined){
                        cur_val = $('#venovani2 textarea[name="voucher[items][' + i + '][dedication]"]').val();
                    }
                    if($("#dedication_type").val() == 2){
                        html += '<div class="form__row"><label class="label">' + (parseInt(i)+1) + '. ' + translations['Text věnování'] + '</label><div class="form__textarea__chars"><span>' + parseInt(cur_val.length) + '</span> ' + translations['znaků z 60'] + '</div><textarea required class="form__textarea js-textarea-count" maxlength="60" name="voucher[items][' + i + '][dedication]">' + cur_val + '</textarea></div>';
                    }else{
                        html += '<div class="form__row"><label class="label">' + (parseInt(i)+1) + '. ' + translations['Text věnování'] + '</label><div class="form__textarea__chars"><span>' + parseInt(cur_val.length) + '</span> ' + translations['znaků z 60'] + '</div><textarea required class="form__textarea js-textarea-count" maxlength="60" disabled name="voucher[items][' + i + '][dedication]">' + cur_val + '</textarea></div>';
                    }

                }

            }
            $("#venovani2").html(html);
        }

        $input.trigger('change');
    });

    $('[name="voucher[price]"], [name="voucher[count]"]').change(function(){
        var price = parseInt($('[name="voucher[price]"]').val());
        var count = parseInt($('[name="voucher[count]"]').val());

        if(!(count > 0)){
            count = 1;
        }

        let totalPrice = price * count;
        let percentageDiscountElem = $('[name="discount_percentage"]');
        if (percentageDiscountElem.length > 0) {
            let discount = percentageDiscountElem.val();
            if (discount > 0) {
                totalPrice = totalPrice - (totalPrice * discount / 100);
            }
        }

        $('[name="total-price"]').val(totalPrice);
    });

    $('[name="voucher[discount_percentage]"]').change(function(){
        let discount = parseInt(this.value);
        let price = parseInt($('[name="voucher[price]"]').val());
        let count = parseInt($('[name="voucher[count]"]').val());

        let totalPrice = price * count;
        if (discount > 0) {
            totalPrice = totalPrice - (totalPrice * discount / 100);
        }

        $('[name="total-price"]').val(totalPrice);
    });

    $("#ks").change(function(){
        if($(this).val() > 1){
            $('.form__counter[data-input="ks"]').children(".minus").removeClass("disabled");
        }else{
            $('.form__counter[data-input="ks"]').children(".minus").addClass("disabled");
        }
    });

    //textarea voucher dedication_type
    $('#dedication_type_changer input').on('shown.bs.tab', function (e) {
        var target = $(e.target).attr("data-target");

        $("#venovani1 textarea, #venovani2 textarea").prop('disabled', true);

        if(target == "#venovani1"){
            $("#dedication_type").val(1);
            $("#venovani1 textarea").prop('disabled', false);
        }else if(target == "#venovani2"){
            $("#dedication_type").val(2);
            $("#venovani2 textarea").prop('disabled', false);
        }else if(target == "#venovani3"){
            $("#dedication_type").val(0);
        }
    });

    //stores
    $(".store").click(function(e){
        e.preventDefault();
        //only one selected
        $(".store").removeClass("selected");
        $(this).addClass("selected");

        $(".js-stores-btn").removeClass("btn--disabled");
        $(".js-stores-btn").removeAttr("disabled");

        $("#js-store_id").val($(this).attr("data-id"));
    });

    //popups
    $('a[data-popup]').bind('click', function(e){
        e.preventDefault();
        $("#" + $(this).attr("data-popup")).addClass("active");
    });

    $('.btn-services-popup').click(function(e){
        e.preventDefault();

        $('html').addClass('popup-active');

        var $popup = $(".services-popup");
        $popup.addClass("services-popup--active");

        var selectedArea = $('form .selected_area .custom_voucher_selected_items').html();

        $('.services-popup-sidebar .custom_voucher_selected_items').html(selectedArea);

        $('form .selected_area .selected-service').each(function(){

            var id = $(this).attr('data-id');
            var groupId = $(this).attr('data-group-id');
            var count = $(this).find('.service-count').val();

            $('.services-popup-sidebar #service-count-'+id+'-'+groupId).val(count);
        });

        initializeServiceCount();

        /*$('.services-popup-sidebar .custom_voucher_selected_items .selected-service .form__checkbox__close').click(function(){
            $(this).parent().remove();
            setServicesCount();
        });*/

        setServicesCount();

        searchServicesAjax();

        calculateServicesPricePopup();

        /*
            var exceptIds = [];
            $("form .custom_voucher_selected_items label").each(function(){
                exceptIds.push($(this).attr("data-id"));
            });

        */
    });

    $(".services-popup .js-close").click(function(e){
        e.preventDefault();

        var $popup = $(this).parents(".services-popup");
        $popup.removeClass("services-popup--active");

        $('html').removeClass('popup-active');
    });

    $(".popup.popup--white .js-close").bind('click', function(e){
        e.preventDefault();

        $(this).parent().removeClass('active');
        $(this).parents(".popup__cont").removeClass("active");
    });

    //prodejní položky popup submit

    $(".services-popup .js-submit-popup").click(function(e){
        e.preventDefault();

        var $popup = $(this).parents(".services-popup");
        $popup.removeClass("services-popup--active");

        $('html').removeClass('popup-active');

        var selectedArea = $('.services-popup-sidebar .selected_area .custom_voucher_selected_items').html();

        $('form .selected_area').removeClass('hidden').find('.custom_voucher_selected_items').html(selectedArea);

        $('.services-popup-sidebar .selected-service').each(function(){
            var id = $(this).attr('data-id');
            var groupId = $(this).attr('data-group-id');
            var count = $(this).find('.service-count').val();

            $('form .selected_area #service-count-'+id+'-'+groupId).val(count);
        });

        initializeServiceCount();

        $('form .selected_area .selected-service .form__checkbox__close').click(function(){
            $(this).parents('.selected_area').find('.js-number_services').text($('form .selected_area .selected-service').length - 1);
            $(this).parent().remove();
            calculateServicesPrice();
        });

        calculateServicesPrice();

        return;




        $popup.find("#custom_autocomplete_wrap input:checked").each(function(){
            if($(".custom_voucher_selected_items").find("#" + $(this).attr("id")).length > 0){

            }else{
                $(".custom_voucher_selected_items").append($(this).parent("label").get(0).outerHTML);
                $(".custom_voucher_selected_items input").prop('checked', true);
            }
        });

        if($(".custom_voucher_selected_items input").length > 0){
            $(".custom_voucher_selected_items_wrap").removeClass("hidden");
            $(".js-number_services").text($("form .custom_voucher_selected_items input").length);
            //projít checkboxy a nastavit pořadí od 0
            $(".custom_voucher_selected_items input").each(function(index){
                $(this).attr("name", "voucher[services][" + index + "][service_id]");
            });
        }else{
            $(".custom_voucher_selected_items_wrap").addClass("hidden");
        }

        //odcheckovat položky v popupu
        $("#custom_autocomplete_wrap input").prop('checked', false);
        calculateServicesPrice();
    });

    $('form .selected_area .selected-service .form__checkbox__close').click(function(){
        $(this).parents('.selected_area').find('.js-number_services').text($('form .selected_area .selected-service').length - 1);
        $(this).parent().remove();
        calculateServicesPrice();
    });

    //prodejní položky smazat při kliku na checkbox
    $(".custom_voucher_selected_items").on("click", "label", function(e){
        e.preventDefault();
        $('.custom_voucher_selected_items label[data-id="' + $(this).attr("data-id") + '"]').remove();

        $('#custom_autocomplete_wrap label[for="' + $(this).attr("for") + '"] input').prop('checked', false);
        //checknout položky
        if($(".custom_voucher_selected_items input").length > 0){
            $(".custom_voucher_selected_items_wrap").removeClass("hidden");
            $(".js-number_services").text($("form .custom_voucher_selected_items input").length);
            //projít checkboxy a nastavit pořadí od 0
            $(".custom_voucher_selected_items input").each(function(index){
                $(this).attr("name", "voucher[services][" + index + "][service_id]");
            });
        }else{
            $(".custom_voucher_selected_items_wrap").addClass("hidden");
        }
        $(".js-number_services").text($("form .custom_voucher_selected_items input").length);
        //searchServicesAjax();
        calculateServicesPrice();
    });

    //POPUP PRODEJNÍ POLOŽKY AJAX
    var delayTyping = (function(){
        var timer = 0;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })();

    $(".popup_custom_autocomplete").keyup(function(){
        var $input = $(this);
        delayTyping(function(){
            searchServicesAjax();
        }, 200 );
    });

    if($(".popup_custom_autocomplete").length > 0){
        searchServicesAjax();
    }


    //AUTOCOMPLETE
    $(".js-autocomplete").autocomplete({
        source: function( request, response ) {
            $.ajax({
                url: "/alfax-voucher/search-products",
                //dataType: "jsonp",
                data: {
                    searchValue: request.term
                },
                success: function( data ) {
                    response($.map(data, function (item) {
                        var name = item.name;

                        if(item.alternative_name != ''){
                            name += ' (' + item.alternative_name + ')';
                        }

                        return {
                            label: name,
                            value: item.id,
                            price: item.price,
                        };
                    }));
                }
            });
        },
        select: function( event, ui ) {
            event.preventDefault();
            $("#voucherPrice").val(ui.item.price).trigger('change');
            $(".js-product_price").text(formatNumber(ui.item.price));
            $('[name="voucher[original_price]"]').val(ui.item.price);

            if(userRole == 'salesman' || userRole == 'receptionist' || userRole == 'receptionist_help'){
                $(".js-product_price").parents(".discount-price").removeClass("hidden");
            }

            $(".js-autocomplete").val("");
            $(".js-product_name").text(ui.item.label);
            $(".js-product_name").parents(".form__product").removeClass("hidden");
            $("#js_product_id").val(ui.item.value);
            $(".js-autocomplete").blur();

        }
    });

    //DATEPICKER
    $.datepicker.setDefaults( $.datepicker.regional[ "cs" ] );

    $( ".form__input__date input" ).datepicker();
    $( ".form__input__date input" ).each(function(index, element){
        if (typeof $(element).data('mindate') !== 'undefined') {
            $(element).datepicker("option", "minDate", $(element).data('mindate'));
        }
    });

    $.validator.addMethod("productAutocomplete", function(value, element){
        $productHiddenInput = $("#js_product_id");

        if($productHiddenInput.val() == "" || $productHiddenInput.val() == 0){
            return false;
        }

        return true;
    }, "Pole musí být vyplněno.");

    //VALIDATION
    $(".js_form_validate").validate({
        ignore: ":hidden:not(select)", // :not(#prodejni_polozka_field)
        errorClass: "form__error-message",
        highlight: function(element) {
            var $element = $(element);

            if($element.is('select')){
                $element.parents(".select-box").addClass("form__input--error");
            }
            else {
                $element.parents(".form__input").addClass("form__input--error");
            }
        },
        unhighlight: function(element) {
            var $element = $(element);

            if($element.is('select')){
                $(element).parents(".select-box").removeClass("form__input--error");
            }
            else {
                $(element).parents(".form__input").removeClass("form__input--error");
            }
        },
        rules: {
            "user[passwordConfirm]": {
                equalTo: "#passwordInput",
            },
            autocomplete: {
                productAutocomplete: true
            },
            "voucher[price]": {
                number: true,
                min: 0,
                required: true,
            },
            "voucher[count]": {
                number: true,
                min: 1,
                required: true,
            },
            "service-group[order]": {
                number: true,
                min: 0,
                required: true,
            },
            "service[price]": {
                number: true,
                min: 0,
                required: true,
            },
            "service[discount_price]": {
                number: true,
                min: 0,
                required: true,
            },
            "voucher[invoice_number]": {
                required: true
            }
        },
        messages: {
            prodejni_polozka_field: translations['Vyplňte prodejní položky'],
        }
    });
    /*
        $('.benefit-voucher-form').submit(function(evt){
            var $selects = [
                $('select[name="voucher[workplacement_id]"]'),
                $('select[name="voucher[work_at_store_id]"]'),
                $('select[name="voucher[workplacement_time_id]"]')
            ];

            result = true;

            for(var key in $selects){
                if($selects[key].val() == '0'){
                    result = false;
                }
            }

            if(result == false){
                evt.preventDefault();
            }

            return result;
        });
    */
    //registration sheet
    $(".js_user_registration_sheet").on('selectmenuchange', function() {
        //$(".js_registration_sheet_number").text($(this).children('[value="' + $(this).val() + '"]').attr("data-number"));
        //$("#registration_sheet_label_name").val($(this).children('[value="' + $(this).val() + '"]').text());
        //$("#registration_sheet_label_number").val($(this).children('[value="' + $(this).val() + '"]').attr("data-number"));
    });

    //user delete
    /*if($('#usersDataTable').length > 0){
        $("body").on('click',".js_confirm_delete", function(e) {
            e.preventDefault();
            var result = confirm(translations['Opravdu chcete smazat tuto položku?']);
            if (result) {
                //Logic to delete the item
                window.location.href = $(this).attr('href');
            }
        });
    }*/

    //ajax náhledy
    $(".js_form_nahled a").click(function(e){
        e.preventDefault();
        var link = $(this).attr("href");
        var form = $(this).parents("form");
        if(form.valid()){
            $.post( form.attr("action"), form.serialize(), function( data ) {
                var win = window.open(link, '_blank');
                win.focus();
            });
        }
    });

    $('.form__input__file input[type="file"]').change(function(evt){
        var fileInput = $(this);
        var files = evt.target.files;

        if(files.length == 0){
            return;
        }

        var reader = new FileReader();
        reader.onload = function(e){
            var result = e.target.result;

            $.ajax({
                url: '/file-upload',
                type: 'POST',
                data: { 'fileContent': result, 'fileName': files[0].name },
                complete: function(response){
                    $('.existing-logo a').text(files[0].name).attr('href', '/logos/' + files[0].name);
                    $('.existing-logo').removeClass('hidden');

                    fileInput.val('');

                }
            });
        };

        reader.readAsDataURL(files[0]);
    });

    $('#logo').change(function(){
        if(!$(this).is(':checked')){
            $.ajax({
                url: '/file-delete',
                type: 'GET',
                complete: function(response){
                    $('.existing-logo').addClass('hidden');
                    $('.existing-logo a').text('').attr('href', '');
                }
            });
        }
    });

    if($('.existing-logo a').text() != ''){
        $('#logo').trigger('click');
    }


    $('.validity-buttons .btn').click(function(){
        /*if($(this).hasClass('btn--primary_bordered')){*/

        $('.validity-buttons .btn').removeClass('btn--primary').addClass('btn--primary_bordered');
        $(this).removeClass('btn--primary_bordered').addClass('btn--primary');

        var months = parseInt($(this).attr('data-months'));

        var $issueDate = $('input[name="voucher[issue_date]"]');

        if($issueDate.length > 0){
            var $validityFromDate = $('input[name="voucher[issue_date]"]');
        }
        else {
            var $validityFromDate = $('input[name="voucher[validity_from_date]"]');
        }

        if($validityFromDate.length > 0 && $validityFromDate.val() != ''){
            var validityFromDate = $validityFromDate.val().split('.');

            validityFromDate = new Date(
                parseInt(validityFromDate[2]),
                parseInt(validityFromDate[1]) - 1,
                parseInt(validityFromDate[0])
            );
        }
        else {
            var validityFromDate = new Date();
        }

        var validityDate = new Date(validityFromDate.setMonth(validityFromDate.getMonth() + months));

        $('input[name="voucher[validity_date]"]').val(zeroFill(validityDate.getDate(), 2) + '.' + zeroFill(validityDate.getMonth() + 1, 2) + '.' + validityDate.getFullYear());

        /* } */
    });

    $('input[name="voucher[validity_date]"],input[name="voucher[validity_from_date]"],input[name="voucher[issue_date]"]').change(function(evt){
        var $issueDate = $('input[name="voucher[issue_date]"]');

        var $validityDate = $('input[name="voucher[validity_date]"]');

        var validityDate = $validityDate.val();

        var $currentBtn = $('.validity-buttons .btn.btn--primary');

        $('.validity-buttons .btn').removeClass('btn--primary').addClass('btn--primary_bordered');

        $('.validity-buttons .btn').each(function(){
            var months = parseInt($(this).attr('data-months'));

            if($issueDate.length > 0){
                var $validityFromDate = $('input[name="voucher[issue_date]"]');
            }
            else {
                var $validityFromDate = $('input[name="voucher[validity_from_date]"]');
            }

            if($validityFromDate.length > 0 && $validityFromDate.val() != ''){
                var validityFromDate = $validityFromDate.val().split('.');

                validityFromDate = new Date(
                    parseInt(validityFromDate[2]),
                    parseInt(validityFromDate[1]) - 1,
                    parseInt(validityFromDate[0])
                );
            }
            else {
                var validityFromDate = new Date();
            }

            var expectedValidityDate = new Date(validityFromDate.setMonth(validityFromDate.getMonth() + months));

            expectedValidityDate = zeroFill(expectedValidityDate.getDate(), 2) + '.' + zeroFill(expectedValidityDate.getMonth() + 1, 2) + '.' + expectedValidityDate.getFullYear();

            if(expectedValidityDate == validityDate){
                $(this).addClass('btn--primary').removeClass('btn--primary_bordered');
            }
        });

        if($(evt.currentTarget).is('[name="voucher[issue_date]"]')){
            $currentBtn.trigger('click');
        }

    }).trigger('change');

    $('.subtitle-buttons .btn').click(function(){
        if($(this).hasClass('btn--primary_bordered')){
            $('.subtitle-buttons .btn').removeClass('btn--primary').addClass('btn--primary_bordered');
            $(this).removeClass('btn--primary_bordered').addClass('btn--primary');

            $('input[name="voucher[subtitle]"]').val($(this).attr('data-subtitle'));
        }
    });

    $('input[name="voucher[subtitle]"]').change(function(){
        var newSubtitle = $(this).val();

        $('.subtitle-buttons .btn').each(function(){
            if($(this).attr('data-subtitle') == newSubtitle){
                $(this).removeClass('btn--primary_bordered').addClass('btn--primary');
            }
            else {
                $(this).removeClass('btn--primary').addClass('btn--primary_bordered');
            }
        });
    });

    $('#changeRoleForm select').change(function(){
        $(this).parents('form').submit();
    });

    $(".js-autocomplete-benefit").autocomplete({
        source: function( request, response ) {
            $.ajax({
                url: "/benefit-voucher/search-products",
                //dataType: "jsonp",
                data: {
                    searchValue: request.term,
                    storeId: $('select[name="pattern[apply_at_store_id]"]').val()
                },
                success: function( data ) {
                    response($.map(data, function (item) {
                        var name = item.name;

                        if(item.alternative_name != ''){
                            name += ' (' + item.alternative_name + ')';
                        }

                        return {
                            label: name,
                            value: item.id,
                            price: item.price,
                        };
                    }));
                }
            });
        },
        focus: function( event, ui ) {
            event.preventDefault();
        },
        select: function( event, ui ) {
            event.preventDefault();
            $("#voucherPrice").val(ui.item.price);
            $(".js-product_price").text(formatNumber(ui.item.price));
            $('[name="voucher[original_price]"]').val(ui.item.price);

            if(userRole == 'salesman' || userRole == 'receptionist' || userRole == 'receptionist_help'){
                $(".js-product_price").parents(".discount-price").removeClass("hidden");
            }

            $(".js-product_name").text(ui.item.label);
            $(".js-product_name").parents(".form__product").removeClass("hidden");
            $("#js_product_id").val(ui.item.value);
        }
    });

    $('.benefit-voucher-form select').change(function(){

        var data = {};

        $('.benefit-voucher-form select.autocomplete-select').each(function(){
            var name = $(this).attr('name').replace('voucher[', '').replace(']', '');

            data[name] = $(this).val();
        });

        if(data.workplacement_id != '0'
            && data.workplacement_time_id != '0'
            && data.work_at_store_id != '0'){
            $.ajax({
                url: '/benefit-voucher/search-patterns',
                method: "post",
                data: {
                    pattern: data
                },
                success: function(data){
                    var data = JSON.parse(data);

                    var $list = $('.benefit-vouchers-list');
                    $list.html('');

                    var lastStoreName = '';
                    var useDedication = false;

                    for(var key in data){
                        if (!useDedication && data[key].use_dedication) {
                          useDedication = true;
                        }
                        if(data[key].apply_at_store_name != lastStoreName){
                            lastStoreName = data[key].apply_at_store_name;

                            $list.append(' \
								<div class="benefit-voucher-item"> \
									<div class="form__row"> \
										<div class="form__product"> \
											<div class="form__product__value">' + lastStoreName + '</div> \
										</div> \
									</div> \
								</div> \
							');
                        }

                        $list.append(' \
							<div class="benefit-voucher-item" data-product-id="' + data[key].alfax_product_id + '" data-store-id="' + data[key].apply_at_store_id + '"> \
								<div class="form__row"> \
									<div class="form__product"> \
										<div class="form__product__name voucher-name">' + data[key].count + 'x ' + data[key].alfax_product_name + '</div> \
									</div> \
								</div> \
							</div> \
						');
                    }
                  if (useDedication) {
                    $(' \
                      <div class="form__row">\
                        <label class="label">'+ translations['Text věnování'] + '</label> \
                        <div class="form__textarea__chars"><span>0</span> ' + translations['znaků z 60'] + '</div>\
                        <textarea class="form__textarea js-textarea-count" required name="voucher[dedication]" maxlength="60" aria-required="true"></textarea> \
                      </div>\
                    ').insertAfter($list);
                  }
                }
            });
        }

    });

    $('select.alfax-product-select').change(function(){
        var value = $(this).val();

        var storeId = $(this).find('option[value="' + value + '"]').attr('data-store-id');

        if(storeId > 0){
            $('select[name="voucher[apply_at_store_id]"]').val(storeId);
        }
        else {
            $('select[name="voucher[apply_at_store_id]"]').val(0);
        }

        $('select[name="voucher[apply_at_store_id]"]').selectmenu("refresh");

        var self = this;

        setTimeout(function(){
            $(self).val(value);
        }, 500);

    });

    $(document.body).on('click', ".custom_voucher_selected_items .selected-service .form__checkbox__close", function(e){
        var id = $(this).parent().attr('data-id') + '-' + $(this).parent().attr('data-group-id');

        $('#sluzba-' + id).prop('checked', false);
        $('#sluzba-' + id).trigger('change');

        $(this).parent().remove();
        setServicesCount();
        calculateServicesPrice();
    });

    /*$('.btn-services-popup').click(function(){
        setTimeout(function(){
            $(document.body).on('click', ".custom_voucher_selected_items .selected-service .form__checkbox__close", function(e){
                var id = $(this).parent().attr('data-id');

                $('#sluzba-' + id).prop('checked', false);
                $('#sluzba-' + id).trigger('change');

                $(this).parent().remove();
                setServicesCount();
                calculateServicesPrice();
            });
        }, 200);
    });*/

    initializeServiceCount();








    $('.add-additional-info').click(function(){

        var content = $('.additional-info-row-pattern').html();
        var count = $('.additional-info-row').length;

        content = content.replace(/additionalInfo\[0\]/g, 'additionalInfo[' + count + ']').replace('additional-info-row-pattern', '');

        $('.additional-info-row-pattern').parent().append('<div class="additional-info-row">' + content + '</div>');

        $('.remove-additional-info').unbind('click').click(function(e){
            var $element = $(this);
            e.preventDefault();

            var result = confirm(translations['Opravdu chcete smazat tuto větu?']);

            if(result){
                var id = $element.parents('.additional-info-row').find('input[type="hidden"]').first().val();

                if(id != ''){
                    $.ajax({
                        url: '/additional-info/delete',
                        method: "get",
                        data: {
                            id: id
                        },
                        success: function(data){
                            $element.parents('.additional-info-row').remove();
                        }
                    });
                }
            }
        });

        var row = $('.additional-info-row').last();

        row.find(".form__radio input").change(function(e){
            $('.form__radio input[name="' + $(this).attr("name") + '"]').parent(".form__radio").removeClass("checked");
            $(this).parent(".form__radio").addClass("checked");
        });

    });

    if(typeof additionalInfo != 'undefined'){

        if(additionalInfo.length > 0){
            for(var key in additionalInfo){

                $('.add-additional-info').trigger('click');

                var count = $('.additional-info-row').length - 1;

                var row = $('.additional-info-row').last();

                row.find('[name="additionalInfo[' + count + '][id]"]').val(additionalInfo[key].id);
                row.find('[name="additionalInfo[' + count + '][text]"]').val(additionalInfo[key].text);

                if(additionalInfo[key].is_service){
                    row.find('[name="additionalInfo[' + count + '][is_service]"][value="true"]').trigger('click');
                }
                else {
                    row.find('[name="additionalInfo[' + count + '][is_service]"][value="false"]').trigger('click');
                }

            }
        }
        else {
            $('.add-additional-info').trigger('click');
        }

    }


    $('.ceo-save').click(function(evt){

        evt.stopPropagation();
        evt.preventDefault();

        var url = $(this).attr('href') + '?ceoId=' + $('#ceoId').val();

        window.location.href = url;

    });

    $('.substitute-save').click(function(evt){

        evt.stopPropagation();
        evt.preventDefault();

        var url = $(this).attr('href') + '?substituteCode=' + $('#substituteCode').val();

        window.location.href = url;

    });

    $('.duplicate-save').click(function(evt){

        evt.stopPropagation();
        evt.preventDefault();

        var url = $(this).attr('href') + '?duplicateCode=' + $('#duplicateCode').val();

        window.location.href = url;

    });

    $('input[name="voucher[omit_address]"]').change(function(){
        var omitAddress = $(this).val();

        if(omitAddress == 1){
            $('.additional-info-list').hide();
            $('.additional-info-list2').show();
        }
        else {
            $('.additional-info-list').show();
            $('.additional-info-list2').hide();
        }
    });

    $('input[name="voucher[omit_address]"]:checked').trigger('change');

    if($('select[name="voucher[subtitle]"]').length > 0){
        calculateServicesPrice();
    }

    $('.popup__cont form').submit(function(evt){
        evt.preventDefault();

        var data = {};

        $(this).find('input,textarea,select').each(function(){
            // cancellation[cancel_reason]
            var name = $(this).attr('name').replace('cancellation[', '').replace(']', '');

            data[name] = $(this).val();
        });

        $.ajax({
            url: $(this).attr('action'),
            method: "post",
            data: {
                cancellation: data
            },
            success: function(data){

                $('.popup__cross').trigger('click');
                $('.dataTable').DataTable().draw('page');

                $payload = JSON.parse(data);
                $('.container-fluid .row').first().prepend(' \
					<div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2"> \
						<div class="alert alert-'+$payload['type']+'">'+$payload['message']+'</div> \
					</div> \
				');

                $('.container-fluid .row').first().prepend('<div class="clear-20"></div>');
            }
        });

    });

    $('table.dataTable').on( 'order.dt search.dt', function () {
        if($(this).find(".sorting_desc").length > 0 || $(this).find(".sorting_asc").length > 0 || $(".dataTables_filter input").val() != ""){
            if($(this).find(".dataTable_reset_link").length > 0){
                $(".dataTable_reset_link").show();
            }else{
                $(this).find("thead tr th:last-child").html('<a href="#" class="dataTable_reset_link"><i class="icon icon-cross"></i> ' + translations['Resetovat filtraci'] + '</a>');
            }
        }else{
            $(".dataTable_reset_link").hide();
        }

    } );

    $("body").on("click", ".dataTable_reset_link", function (e) {
        e.preventDefault();
        $(".dataTable_reset_link").hide();
        var table = $(this).parents("table").dataTable();
        table.fnSortNeutral();
        table.fnFilter("");
    });


    $('#credit_note_check').change(function(){
        if($(this).is(':checked')){
            $('#credit_note').removeAttr('required').attr('disabled', 'true');
        }
        else {
            $('#credit_note').attr('required', 'true').removeAttr('disabled');
        }
    });

    $('#credit_note').change(function(){
        if($(this).val() !== ''){
            $('#credit_note_check').removeAttr('required');
        }
        else {
            $('#credit_note_check').attr('required', 'true');
        }
    });

    $('a.btn-memberpro-send').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();

        var thisButton = $(this);

        $.ajax({
            url: thisButton.data('href'),
            method: "GET",
            beforeSend: function(){
                thisButton.attr('disabled', 'disabled');
                thisButton.addClass('disabled');
                alert('Odesílání poukazů bylo zahájeno, prosím, nezavírejte okno v prohlížeči.');
            },
            complete: function(data){
                try {
                    var response = JSON.parse(data.responseText);

                    if (Array.isArray(response)) {
                        alert(response.join('\n'));

                    } else if (typeof response === 'object' && response.text) {
                        alert(response.text);

                    } else {
                        alert('Neznámý formát odpovědi, pokusíme se uložit chybu pro zpětné dohledání');
                        throw new Error();
                    }

                    if (response.status === "success") {
                        location.reload();
                    } else {
                        thisButton.removeAttr('disabled');
                        thisButton.removeClass('disabled');
                    }
                } catch (e) {
                    $.ajax({
                        url: '/save-error',
                        method: 'POST',
                        data: { responseData: data.responseText},
                        error: function() {
                            alert('Nepodařilo se uložit odpověď.');
                        }
                    });
                }
            }
        });
    })

});


function zeroFill( number, width )
{
    width -= number.toString().length;
    if ( width > 0 )
    {
        return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
    }
    return number + ""; // always return a string
}
